@import url('https://fonts.googleapis.com/css2?family=EB+Garamond&display=swap');

@font-face {
  font-family: BruneiDarussalam;
  src: local('BruneiDarussalam'),
    url('./assets/fonts/BruneiDarussalam.otf') format('opentype');
}

@font-face {
  font-family: GothamBlack;
  src: local('GothamBlack'),
    url('./assets/fonts/Gotham/Gotham-Black.otf') format('opentype');
}

@font-face {
  font-family: GothamBold;
  src: local('GothamBold'),
    url('./assets/fonts/Gotham/Gotham-Bold.otf') format('opentype');
}

@font-face {
  font-family: GothamLight;
  src: local('GothamLight'),
    url('./assets/fonts/Gotham/Gotham-Light.otf') format('opentype');
}

@font-face {
  font-family: GothamThin;
  src: local('GothamThin'),
    url('./assets/fonts/Gotham/Gotham-Thin.otf') format('opentype');
}

body {
  margin: 0;
}

* {
  box-sizing: border-box;
}
