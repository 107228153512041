@import url(https://fonts.googleapis.com/css2?family=EB+Garamond&display=swap);
@font-face {
  font-family: BruneiDarussalam;
  src: local('BruneiDarussalam'),
    url(/static/media/BruneiDarussalam.00929f8f.otf) format('opentype');
}

@font-face {
  font-family: GothamBlack;
  src: local('GothamBlack'),
    url(/static/media/Gotham-Black.bb70e5a3.otf) format('opentype');
}

@font-face {
  font-family: GothamBold;
  src: local('GothamBold'),
    url(/static/media/Gotham-Bold.eb2de6c9.otf) format('opentype');
}

@font-face {
  font-family: GothamLight;
  src: local('GothamLight'),
    url(/static/media/Gotham-Light.3211212b.otf) format('opentype');
}

@font-face {
  font-family: GothamThin;
  src: local('GothamThin'),
    url(/static/media/Gotham-Thin.51b80342.otf) format('opentype');
}

body {
  margin: 0;
}

* {
  box-sizing: border-box;
}

